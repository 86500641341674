.container {
  width: 100%;
  background-color: var(--blue-5);
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  color: var(--blue-1);
  /* margin-top: 38px; */
  /* margin-bottom: 20px; */
  line-height: 3.25rem; /* 108.333% */
  text-align: center;
  margin-bottom: 1.5rem;
}
.desc {
  color: var(--light-gray);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
  letter-spacing: -0.01125rem;
  margin-bottom: 4rem;
}

.detailsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 43px;
  gap: 10px;
}

.swiperSlide {
  border-radius: 20px;
  background-color: transparent;
}
.singleCardContainer {
  background-color: var(--black);
  border-radius: 22px;
  display: flex;
  width: 100%;
  height: fit-content;
}
.firstSection {
  border-radius: 22px;
  width: 50%;
  min-height: 525px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.firstSectionBlurBg {
  border-radius: 50%;
  align-self: flex-end;
  width: 80%;
  height: 150px;
  background: linear-gradient(
        to bottom right,
        #254dda 14%,
        #025e66 33%,
        rgba(37, 77, 218, 0.88) 49%
      )
      bottom right / 50% 50% no-repeat,
    linear-gradient(to bottom left, #254dda 14%, #025e66 33%, rgba(37, 77, 218, 0.88) 49%) bottom
      left / 50% 50% no-repeat,
    linear-gradient(to top left, #254dda 14%, #025e66 33%, rgba(37, 77, 218, 0.88) 49%) top left /
      50% 50% no-repeat,
    linear-gradient(to top right, #254dda 14%, #025e66 33%, rgba(37, 77, 218, 0.88) 49%) top right /
      50% 50% no-repeat;

  filter: blur(100px);
}
.textContainer {
  display: flex;
  padding: 0 60px 60px 60px;

  flex-direction: column;
  justify-content: flex-end;

  /* border: 1px solid green; */
}

.cardTopTitle {
  background: linear-gradient(90deg, #5171e1 -5.16%, #6188bb 37.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75rem; /* 200% */
  letter-spacing: 0.00875rem;
  text-transform: uppercase;
}

.cardTitle {
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem; /* 125% */
  letter-spacing: -0.02rem;
  margin-bottom: 36px;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  font-size: 0.8125rem;
  line-height: 1.25rem; /* 153.846% */
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.12);
  color: var(--light-gray-1);
}

.imageContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.imageSubContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.cardImg {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
}

.progressContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.singleProgressContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 275px;
  cursor: pointer;
}
.progressBarContainer {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
  /* border: 1px solid green; */
  /* background: var(--blue-2); */
  /* opacity: .1; */
  /* margin-bottom: 20px; */
}
.progressBar {
  width: 100%;
  height: 2px;
  background: var(--blue-2);
  opacity: 0.1;
  /* margin-bottom: 20px; */
}
.progressBarPseudo {
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--blue-2);
  /* margin-bottom: 20px; */
  z-index: 12;
  top: 0;
}

.progressText {
  color: var(--blue-2);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
  margin-bottom: 14px;
  top: 0;
  margin-top: 20px;
}
.progressDesc {
  color: var(--blue-1);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
  max-width: 283px;
}

@media only screen and (max-width: 800px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
  .singleCardContainer {
    flex-direction: column;
    gap: 50px;
    max-height: 1000px;
  }
  .firstSection,
  .imageContainer {
    width: 100%;
  }
  .imageContainer {
    width: 100%;
    height: 300px;
    position: relative;
    /* max-height: 300px; */
    /* padding-bottom: ; */
  }
  .imageSubContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  }
  .cardImg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .textContainer {
    padding: 0 20px;
  }
  .firstSectionBlurBg {
    height: 50px;
  }
  .firstSection {
    border-radius: 22px;
    width: 100%;
    min-height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .progressContainer {
    margin-top: 1rem;
  }
  .cardImg {
    object-fit: fill;
  }
  .firstSection  {
    min-height: 330px;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
  .imageSubContainer {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .progressText,
  .progressDesc {
    font-size: 14px;
  }
  .desc {
    font-size: 16px;
  }
  .cardTopTitle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}
