.container {
  position: relative;
  display: flex;
  padding: 120px 0px 240px 0px;
  flex-direction: column;
  align-items: center;
  gap: 103px;
  align-self: stretch;
  overflow: hidden;
  min-height: 80vh;
  background-color: #f5f7ff;
}

.ellipse25 {
  position: absolute;
  top: -208px;
  width: 100%;
  max-width: 1920px;
  height: 249px;
  border-radius: 1920px;
  background: linear-gradient(
        to bottom right,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      bottom right / 50% 50% no-repeat,
    linear-gradient(
        to bottom left,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      bottom left / 50% 50% no-repeat,
    linear-gradient(
        to top left,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      top left / 50% 50% no-repeat,
    linear-gradient(
        to top right,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      top right / 50% 50% no-repeat;
  filter: blur(250px);
  z-index: 0;
}

.ellipse26 {
  position: absolute;
  bottom: -96px;
  width: 100%;
  max-width: 1920px;
  height: 249px;
  border-radius: 1920px;
  opacity: 0.5;
  background: linear-gradient(
        to bottom right,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      bottom right / 50% 50% no-repeat,
    linear-gradient(
        to bottom left,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      bottom left / 50% 50% no-repeat,
    linear-gradient(
        to top left,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      top left / 50% 50% no-repeat,
    linear-gradient(
        to top right,
        rgba(34, 64, 205, 0.71) 16%,
        #3498db 34%,
        rgba(37, 77, 218, 0.43) 47%
      )
      top right / 50% 50% no-repeat;
  filter: blur(250px);
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 848px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 0 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.errorCode {
  margin: 0;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Instrument Sans', sans-serif;
  font-size: 240px;
  font-style: normal;
  font-weight: 700;
  line-height: 240px;
  letter-spacing: -10px;
  background: linear-gradient(96deg, #254dda 26.12%, #3498db 76.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.errorText {
  color: #000041;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Instrument Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0;
}

.link {
  color: #0f72ee;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Instrument Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 80px 0px 160px 0px;
    gap: 60px;
  }

  .errorCode {
    font-size: 160px;
    line-height: 160px;
    letter-spacing: -6px;
  }

  .errorText {
    font-size: 18px;
    line-height: 24px;
  }

  .link {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 120px 0 80px;
    gap: 40px;
    min-height: calc(80vh - 200px);
  }

  .errorCode {
    font-size: 120px;
    line-height: 120px;
    letter-spacing: -4px;
  }

  .errorText {
    font-size: 16px;
    line-height: 22px;
  }

  .link {
    font-size: 16px;
    line-height: 22px;
  }

  .content {
    gap: 32px;
  }

  .textContainer {
    gap: 16px;
  }
}

/*  */

.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
