.cycleContainer {
  display: flex;
  flex-direction: column;
}
.header {
  color: var(--blue-1);
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.25rem;
  margin-bottom: 8rem;
}
.firstRow,
.secondRow {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.singleCard {
  max-width: 350px;
  min-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}
.singleCard img {
  max-width: 226px;
  min-height: 260px;
  max-height: 260px;
  height: 100%;
  object-fit: contain;
}

.cardTitle {
  color: var(--blue-1);
  text-align: center;
  font-size: 1.904rem;
  font-weight: 600;
  line-height: 4.36325rem;
  letter-spacing: 0.03806rem;
}

.cardDesc {
  color: var(--light-gray);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: -0.01125rem;
}

.topArrow,
.topL {
  align-self: flex-end;
  max-width: 175px;
  /* min-width: 73px; */
}
.bottomArrow,
.bottomL {
  align-self: flex-start;
  max-width: 175px;
  /* min-width: 73px; */
}

.bottomArrow {
  margin-left: 5px;
}

.forwardArrow {
  margin-bottom: 166px;
}
.backwardArrow {
  margin-top: 166px;
}

@media only screen and (max-width: 1350px) {
  .forwardArrow,
  .backwardArrow {
    width: 125px;
  }
}

@media only screen and (max-width: 1200px) {
  .forwardArrow,
  .backwardArrow {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .forwardArrow,
  .backwardArrow {
    display: none;
  }
  .singleCard {
    max-width: 241px;
    min-width: 241px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .singleCard img {
    max-width: 176px;
    min-height: 139px;
    max-height: 139px;
    height: 100%;
    object-fit: contain;
  }
  .cardTitle {
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .topArrow,
  .bottomArrow,
  .topL,
  .bottomL {
    height: 195px;
  }
  .bottomArrow {
    margin-left: 7px;
  }
  .topArrow {
    margin-left: -2px;
  }
}

@media only screen and (max-width: 800px) {
  .forwardArrow,
  .backwardArrow,
  .topArrow,
  .bottomArrow,
  .topL,
  .bottomL {
    display: none;
  }
  .singleCard {
    max-width: 45%;
    min-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .singleCard img {
    max-width: 176px;
    min-height: 139px;
    max-height: 139px;
    height: 100%;
    object-fit: contain;
  }
  .cardTitle {
    line-height: 1.2;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 550px) {
  .forwardArrow,
  .backwardArrow,
  .topArrow,
  .bottomArrow,
  .topL,
  .bottomL {
    display: none;
  }
  .singleCard {
    max-width: 95%;
    min-width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .singleCard img {
    max-width: 176px;
    min-height: 139px;
    max-height: 139px;
    height: 100%;
    object-fit: contain;
  }
  .cardTitle {
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .firstRow,
  .secondRow {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
}
