.twoPartsInfoBoxesSection {
  width: 100%;
  background-color: #ffffff;
  /* padding: 6rem 1.5rem; */
  padding: 120px;
}

.twoPartsInfoBoxesSection .container {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twoPartsInfoBoxesSection .container .main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.twoPartsInfoBoxesSection .container .main .left .imageContainer .image,
.twoPartsInfoBoxesSection .container .main .right .imageContainerMobile .image {
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}
.left {
  width: 50%;
}

.twoPartsInfoBoxesSection .container .main .right {
  width: 45%;
}

.twoPartsInfoBoxesSection .container .main .right .imageContainerMobile {
  display: none;
}
.topTitle {
  color: var(--blue-2);
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: 0.14px;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.twoPartsInfoBoxesSection .container .main .right .title {
  /* max-width: 33.625rem; */
  color: var(--blue-1);
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  /* margin: 24px 0; */
  line-height: 3.25rem;
}

.twoPartsInfoBoxesSection .container .main .right .heading {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: -0.18px;
  color: var(--light-gray);
  margin-bottom: 48px;
}

.twoPartsInfoBoxesSection .container .main .right .subHeading {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.1px;
  color: #403b58;
  margin-bottom: 3rem;
}

.infoBoxesContainer {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.twoPartsInfoBoxesSection .container .main .right .infoBoxesContainer .infoBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  max-width: 31rem;
  border-radius: 8px;
}

.infoBoxesContainer .infoBox .infoBoxTitle {
  color: var(--blue-1);
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.18px;
}

.infoBoxesContainer .infoBox .infoBoxDescription {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.01125rem;
  margin-top: 0.5rem;
}

.container .main .right .primaryCta {
  margin-top: 4rem;
}

.btnSection {
  margin-top: 2.5rem;
}

@media screen and (max-width: 1050px) {
  .twoPartsInfoBoxesSection {
    padding: 5rem 1.5rem !important;
  }

  .twoPartsInfoBoxesSection .container .main {
    flex-direction: column-reverse !important;
    flex-wrap: wrap;
  }

  .twoPartsInfoBoxesSection .container .main .right {
    margin-left: 0;
    width: 100%;
  }

  .twoPartsInfoBoxesSection .container .main .right .imageContainerMobile {
    display: block;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
  }

  .twoPartsInfoBoxesSection .container .main .left {
    display: none;
  }

  .twoPartsInfoBoxesSection .container .main .right .title {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .container .main .right .primaryCta {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .twoPartsInfoBoxesSection .container .main .right .title {
    font-size: 2.8rem;
    line-height: 1.2;
  }
  .infoBoxesContainer .infoBox .infoBoxTitle {
    font-size: 1.24rem;
    line-height: 1.2;
  }
  .twoPartsInfoBoxesSection .container .main .right .heading {
    font-size: 16px;
  }
  .listIcon {
    height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .twoPartsInfoBoxesSection .container {
    width: var(--container-responsive-width);
  }
}
