.blueCardContainer {
  margin-top: 160px !important;
}


@media (min-width: 401px) and (max-width: 500px) {
  .blueCardContainer {
    padding-top: 50% !important;
  }
}
