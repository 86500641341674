.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.header {
  max-width: 710px;
  margin-bottom: 24px;
  /* text-align: center; */
}
.desc {
  color: var(--light-gray);
  max-width: 941px;
  text-align: center;
  margin-bottom: 64px;
}

.cardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 20px;
}
.singleCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 427px;
  /* width: 30%; */
  border-radius: 20px;
  justify-content: space-between;
}
.imageContainer {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  padding: 31px;
  background-color: #d3e1fa;
  max-height: 319px;
  min-height: 319px;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 36px 36px;
  gap: 13px;
}
.cardTitle {
  margin-top: 30px;
  color: var(--blue-1);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.cardDesc {
  color: var(--blue-1);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
@media screen and (max-width: 1400px) {
  .cardContainer {
    flex-wrap: wrap;
  }
  .singleCard {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .cardContainer {
    flex-wrap: wrap;
  }
  .singleCard {
    width: 40%;
    min-width: 327px;
  }
}

@media screen and (max-width: 800px) {
  .cardContainer {
    flex-wrap: wrap;
  }
  .singleCard {
    width: 80%;
    min-width: 80%;
  }
  .desc,
  .cardTitle,
  .cardDesc {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .cardContainer {
    flex-wrap: wrap;
  }
  .singleCard {
    width: 90%;
    min-width: 90%;
  }
  .imageContainer {
    max-height: 219px;
    min-height: 219px;
  }
  .desc,
  .cardTitle,
  .cardDesc {
    font-size: 16px;
  }
}
