@media only screen and (max-width: 1300px) {
  .heroImage {
    top: -23rem;
    width: 45rem;
    left: -10rem;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1450px) {
  .heroImage {
    width: 52rem;
    top: -25rem;
  }
}
@media only screen and (min-width: 1450px) {
  .heroImage {
    top: -30rem;
    width: 62rem;
  }
}
